$primary: #52655C; /* MAIN COLOR */
$secondary: #6B8C8A; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
@import url('https://fonts.googleapis.com/css?family=Acme|Oxygen');

h1,h2,h3 {
	font-family: 'Acme', sans-serif;
}
p {
	font-family: 'Oxygen', sans-serif;
	font-size: 1.5em;
}
.flash {
	display:none;
}


.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 30px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }

		&:focus {
		  background: transparent;
		  color: #777;
		  outline: 0;
		}
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
	margin-top: 15px;	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 10px;
	background: $primary;
	color: $footerLinks;
	p {
		@media (max-width: 767px) {
			font-size: 16px;
		}
	}
	a {
		color: $footerLinks;
		@media (max-width: 767px) {
			font-size: 16px;
		}
		@media (max-width: 500px) {
			white-space: nowrap;
		}

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


@media(max-width: 767px) {
  .navbar-header a {
    float: left;
  } 
}

.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}

.banner {
	background: url(../img/banner.jpg) no-repeat;
	background-size: cover;
	height: 500px;
	position: relative;
	@media (max-width: 991px) {
		background-position: 60% 50%;
	}
	@media (max-width: 767px) {
		background-position: 45% 50%;
	}
	h1 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		color: white;
	}
}

.noPad {
	padding: 0;
}

.box1 {
	background: linear-gradient(
       rgba($primary,0),
       rgba($primary,1)
		),url(../img/box1.jpg) no-repeat;
	background-size: cover;
	background-origin: content-box;
	@media (max-width: 991px) {
		background-position: 40% 10%;
	}
	@media (max-width: 767px) {
		background-position: 50% 10%;
	}
	
}
.box2 {
	background: linear-gradient(
       rgba($secondary,0),
       rgba($secondary,1)
		),url(../img/box2.jpg) no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	background-origin: content-box;
	@media (max-width: 767px) {
		background-position: 50% 30%;
	}
}
.box3 {
	background: linear-gradient(
       rgba($primary,0),
       rgba($primary,1)
		),url(../img/box3.jpg) no-repeat;
	background-position: 50% 50%;
	background-origin: content-box;
	background-size: cover;
	@media (max-width: 767px) {
		background-position: 50% 30%;
	}
}

.hideOnSmall {
	@media (max-width: 767px) {
		display: none;
	}
}

body {
	background: url(../img/bg.jpg) no-repeat fixed;
	background-size: cover;
}

.solidBg {
	background: $wht;
}

.box {
	position: relative;
	border-radius: 10px 10px 0 0;
	height: 500px;
	@media (max-width: 767px) {
		height: 300px;
	}
	h1 {
		bottom: 0%;
		left: 50%;
		// width: 80%;
		transform: translate(-50%, -50%);
		position: absolute;
		color: white;
		text-align: center;
		@media (max-width: 991px) {
			font-size: 20px;
		}
	}
}
.bg {
	background: url(../img/banner.jpg) no-repeat;
	background-size: cover;
	padding: 200px 15px;
	h1 {
		color: white;
	}
}

.bg2 {
	background: url(../img/banner2.jpg) no-repeat;
	background-size: cover;
	padding: 200px 15px;
	@media (max-width: 991px) {
		background-position: 60% 50%;
		padding: 100px 15px;
	}
	@media (max-width: 500px) {
		.col-xs-6 {
			width: 100%;
		}
	}
	h1 {
		color: white;
		font-size: 2em;
	}
}
.bg3 {
	background: $secondary;
	padding: 100px 15px;
	p, h1 {
		color: white;
	}
}
.introTxt {
	p {
		font-size: 1.5em;
	}
}

span[class^="icon"] {
	color: white;
	font-size: 6em;
	display: inline-block;
}

a.btn {
	@include btn1;
	margin-bottom: 20px;
}

.logo {
	max-width: 250px;
	width: 100%;
	margin-top: 10px;
	@media (max-width: 767px) {
		max-width: 150px;
	}
}
.navbar-default {
	background: white;
}

nav {
	z-index: 1000;
	width: 100%;
	position: fixed !important;
	left: 50%;
	top: 0;
	transform: translateX(-50%);
	@media (min-width: 768px) {
		max-width: 750px;
	}
	@media (min-width: 992px) {
		max-width: 970px;
	}
	@media (min-width: 1200px) {
		max-width: 1170px;
	}
}
.navbar {
	margin-bottom: 0;
}

.nav.navbar-nav {
	margin-top: 10px;
	@media (max-width: 767px) {
		margin-top: 0;
	}
}


footer a:hover, footer a:focus {
	outline: 0;
	text-decoration: none;
	color: white;
}

.privacy, .terms {
	background: white;
	margin-top: 100px;
	@media (max-width: 767px) {
		margin-top: 60px;
	}
}